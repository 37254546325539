.container {
    display: flex;
    flex-direction: column;
  }
  .container div {
    align-self: center;
    padding: 10px;
    font-size: 30px;
  }
  .container h1 {
    padding: 20px;
  }
.opps {
    max-width: 10%;
    align-self: center;
  }
  .container a {
    text-decoration: none;
 
  }
.carduri{
    display: flex;
    justify-content: space-around;
    gap:2%;
    width: 80%;
    flex-direction: row;
}
.card:hover{
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-5px);
}
@media only screen and (max-width: 600px) {
    .cardText{display: none;}
  
    .container div{
      font-size: 100%;
    }
}