.container{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap:10px;
    margin:10px;
}

.CASlogo{
    width: 10%;
    align-self:  center;
}
p{
    align-self:  center; 
   
  
}
h1{
  margin-top: 20px;
}

a{
    color: red;
}
.submit2{
    border-color: #d0e4e4;
    background-color: #d0e4e4;
    color: #d0e4e4;
    display: none;
    }
    
  .submit{
    width: 100%;
    background-color: rgba(127, 255, 212, 0.497);
    color:black;
    border-color: aquamarine;
     border-radius: 10px;
     margin-top: 8% !important;
  }
  .submit:hover{
    background-color: rgba(0, 255, 170, 0.497);
    border-color: aquamarine;
    color:black;
  }
  
  .form {
    width: 20%;
    display: flex;
    flex-direction: column;
    
  }
  .form input {
    padding: 10px;
  }
  .input:hover {
    background-color: #237e6089;
  
  }
  .input {
    border-radius: 5px;
    border-color: rgb(244, 247, 249);
    border-width: 1px;
  }
  .contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    background-image: linear-gradient( #ccfbeba0,#56887842);
    padding: 20px;
    margin-top:1%;
  }
  .submit {
    width: 50%;
    margin: 0 auto;
  }
  
  .message {
    border-radius: 5px;
    border-color: rgb(244, 248, 252);
  
    border-style: inset;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    .contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      background-color: aliceblue;
      padding: 20px;
    }
    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .figure {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
    
    }
    h4{
      text-align: center;
      font-size: 100%!important;
    
    }
    .contact1{
      display: flex;
  flex-direction: column;
    align-items: center;
    margin-bottom:5%;
    
    
    }
    .socialMediaButtons{
      display: flex;
      justify-content: center;
      gap:10px;
      margin:10px;
      font-size: 150%;
    }
    .submit2{
      
      display: block;
      }
      .CASlogo{
        width: 50%;
        align-self:  center;
    }
    h1{
        margin:0
    }
  
  }
  