footer {
  margin-top: 15%;
}
footer a{
  color:rgb(255, 255, 255) !important;
  text-decoration: none;
}
.socialButtons {
  display: flex;

  justify-content: center;
  gap: 2%;
  font-size: 1.5rem;
  margin: 20px;
}
.anpc{

gap: 10px;
}
.anpc img{
  width: 15%;
  margin-left:10px

}
/* .footer1{
  background-image: linear-gradient( #a4f9dd,#568878);
  text-align: center;
} */
@media only screen and (max-width: 600px) {
  .socialButtons {
    display: flex;
    justify-content: center;
    gap: 10%;
    font-size: 1.5rem;
    margin: 20px;
  }
  footer {
    margin-top: 50%;
  }
  .programeazate{
    text-align: center;
  }
  .anpc img{
    width: 40%;
    margin-left:10px
  
  }
  
}
