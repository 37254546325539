.logo {
  width: 2%;
}
.navbarNavDropdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: larger;
}
nav {
  /* background-image: url("https://i.imgur.com/1Pdcz1S.png");
  background-repeat: repeat;
  background-size: cover; */
  font-size: larger;
  padding: 20px;
}
.flag{
  width: 2rem;
 
}
.flaguri{
  margin-left:2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1%;
}
ul {
  list-style-type: none;
  gap: 10px;
}
.linkuri {
  color: black;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .navbarNavDropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: medium;

  }
.linkuri{
  
 display: block;
 margin-left: -5px;

 
}
ul{
  margin-left: -10%;
}

}

@media only screen and (max-width: 400px) {
  .navbarNavDropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: medium;

  }
.linkuri{
  
 display: block;
 /* margin-left: -10px; */

 
}

.linkuri img {
  width: 100%;
  height: 100%;
}

}