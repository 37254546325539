.pagina{
    display: flex;
    flex-direction: column;
    margin:0

}
.bullet li{
    list-style-type:circle;
}
table{
    border-collapse: collapse;
    width:100%;
  margin-bottom:20px;
  margin-top:20px;

}
ol.a{
    list-style-type: lower-latin;
}
ol.i{
    list-style-type: lower-roman;
}
table,th,td{
    border: 1px solid black;
   
}
tr:hover {background-color: rgba(255, 127, 80, 0);}

.pagina ul {
    list-style-type:decimal;
}

@media only screen and (max-width: 600px) {
    .pagina{
        display: block;
      
    
    }
table{
    width:10%;
}

}

