.sectiune{
    display: flex;
    justify-content:center ;
    
    
    }
    .card{
        border:none;
    }
    .images{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .sectiune img{
        width:25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }
    .sectiune ul{
        list-style-type: upper-roman;
        text-indent: 0px;
    }
    .text{
        text-align: justify;
        
    }
    p{  text-indent: 50px;}
    .titlu{
        text-align: center; 
     
    }
    .link{
        color:black;
    width:30%;
    }
    .cardBody{
        align-self: center;
        display: flex;
        width: 100%;
        justify-content: center;
        
    }
    
    @media only screen and (max-width: 600px) {
        .sectiune img{
            width:50%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            }
    }