.lista{
list-style-type: disc;
margin:20px;
}
.pagina{
    margin:20px;
}
.pagina a{
color: black;
text-decoration: underline;
}