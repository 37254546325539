.despre_noi{
    display: flex;
    flex-direction: row;
    width:60rem;
    align-items: flex-end;
    margin:0 auto;
 
    margin-bottom: 2%;
}
h1{
    font-family: Sans-serif;
  }

.doctor{
    width: 20rem;
}
.imagini_carusel {

    place-content: center;
  }
  
  
  .carusel {
    margin: auto;
    text-align: center;
    width:60rem;
  }

@media only screen and (max-width: 600px) {
    .despre_noi{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .doctor{
        width: 70%;
        margin-bottom: 10%;
        margin:0 auto;
    }
    .carusel {
        margin: auto;
        text-align: center;
        max-width: 100%;
      }
      .imagini_carusel {
        max-width: 100%;
        place-content: center;
      }
}