.servicii {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
 
  
}

.figure {
  max-width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card:hover{
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
  /* transform: scale(1.1);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); */
}
.carduri {
  display: flex;
  flex-direction: column;


}
.card{
    animation: mymove 1s ;
    animation-fill-mode: forwards;
}
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



img{
  pointer-events: none;
  -webkit-touch-callout: none;
}
.figure {
  width: 100%;

  
}

.card {
  width: 25%;
  background: rgb(254, 253, 255);
  transition: width 0.5s;
  margin-bottom: 200px;

}
h2 {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .card {
    /* background-color: lightblue; */
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-bottom: auto;
    
  }
  .card:hover {
    width: 70%;
  }
  .figure{
   max-width: 60%;
  }
  h2{
   font-size:80%;
 margin-left: -50%;
  }
  .servicii {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
  
  }
  .cardText {
   
    font-size: small;
  }
  .footer {
    margin-top: 100px;
  }
  
}
