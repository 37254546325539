.figure {
  max-width: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}
.tabel {
  width: 30%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-bottom: 200px;
}
  
  
.tabel2{
  width: 30%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-bottom: 200px;
  align-self: flex-start;
}
thead {
  background-color: #2c775a;
  padding: 8px;
}


td {
  text-align: left;
  padding: 8px;

  border: 1px solid #ddd;
}

tr:hover {
  background-color: #f9ad46;
}
.tabele{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
 
  align-content:center;
}
th{
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .figure{
    margin-bottom: 10%;
    max-width: 40%;
  }
  .tabel {
    
    width: 70%;
    font-size: small;
    margin-bottom: 0;
  }
  .tabele{
    display: flex;
    flex-direction: column;
    align-content:center;
  } 
  .tabel2{
    width: 70%;
    font-size: small;
    margin-bottom: 0;
  }
}
