.imagini_carusel {
  max-width: 100%;
  place-content: center;

}

.container{
  position: relative;
  text-align: center;
}
.tratamente{
  max-width: 100%;
place-content: center;
}

.carusel {
  margin: auto;
  text-align: center;
}
.link{
  color: black;
}
.servicii {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 2rem;
  margin-bottom: 15rem;
  animation: mymove 2s;
}
.detalii{
display: flex;
margin:100px;
align-content:center;
align-items:center;
gap:30px;
margin-bottom: 200px;

}
.titluCabinet{
  text-align: center;
  font-family: 	Copperplate Gothic;
 
}
.cas{
  width: 100%;
}

.captcha{

clear: both; 
}
.submit2{
border-color: #d4ece4;
background-color: #d4ece4;
color: #d4ece4;
display: none;
}

.titluCabinet2{
  text-align: center;
}
h1{
  font-family: 	Copperplate Gothic;
}

.detalii img{
max-width:80%;

}
.detalii_div{
  max-width: 50%;

}
.butoane, .submit {
  width: 100%;
  background-color: rgba(127, 255, 212, 0.497);
  color:black;
  border-color: aquamarine;
  border-radius: 10px;


}
.submit{
  margin-top: 8% !important;
}

.butoane:hover{
  background-color: rgba(0, 255, 170, 0.497);
  border-color: aquamarine;
  color:black;
} 
 .submit:hover{
  background-color: rgba(0, 255, 170, 0.497);
  border-color: aquamarine;
  color:black;
} 
.cardText{
  text-indent: 0px;
}
.carduri {
  display: flex;
  justify-content: space-between;


  flex-direction: column;
}
.titluri{
  text-align: center;
}

.cardbox:hover {
 transform: scale(1.1);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); 
}
.card {
  transition: 0.5s all ease-in-out;

 
}
@keyframes mymove {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.cardbox {
  overflow: hidden;


}
.acord{
  display: flex;
  flex-direction: row;
gap:10px;
margin-bottom: 10px;
}
.acord2{
  align-self: flex-start;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: -5%;
}
.form {
  width: 20%;
  filter: none;
  display: flex;
  flex-direction: column;
}
.form input {
  padding: 10px;
}
/* .input:hover {
  background-color: rgb(212, 221, 247);
} */
.input {
  border-radius: 5px;
  border-color: rgb(244, 247, 249);
  border-width: 1px;
}


.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  background-image: linear-gradient( #ccfbeba0,#56887842);
  padding: 20px;
  
}
.cabinet{
  animation: mymove 5s ;
}
@keyframes mymove {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
/* @keyframes mymove {
  from {
    background-color: aliceblue;
  }
  to {
    background-color: rgb(198, 229, 255);
  }
} */
.submit {
  width: 50%;

  margin: 0 auto;
}

.message {
  border-radius: 5px;
  border-color: rgb(244, 248, 252);
  border-width: 1px;
  border-style: inset;
  margin-bottom: 20px;
}
@media only screen and (min-width: 600px) {
  .cas{
    display: none;
  }
  .tratamente{
    max-width: 100%;
  place-content: center;
  }
  .imagine_carusel {
   
    display: none !important;

  
  }

}


@media only screen and (max-width: 600px) {
  .captcha{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .tratamente{
    display: none !important;
   

  }
  .imagine_carusel {
    max-width: 100%;
    place-content: center;
  
  }

  .servicii {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20%;
  }
  .contact {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .form {
    width: 100%;
    filter: none;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .cardbox {
    align-items: center;
  }
  .carduri {
    align-items: center;
  }
p {

 text-align:justify;
 margin-left: 0px !important;


  }
  .cardText{
    all: unset;
margin-bottom: 10%;

  }

  .detalii{
    display: flex;
flex-direction: column;
    margin: 10%;
    }
    .titluCabinet{
      text-align: center;
      font-family: 	Copperplate Gothic;
     
    }
    .titluCabinet2{
      text-align: center;
      text-indent: 0;
    
    }
    
    .detalii img{
    max-width:100%;
    
    }
    .detalii_div{
      max-width: 100%;
    
    }
    .cardbox:hover {
      transform:none;
     }
     .card {
       transition:none;
      
     }
     .texte{
    
      text-align: justify;
      text-indent: 0;
      word-spacing: -1px;
      
     }
     h3{
      font-size: 80%;
     }
     .texte1{
      display: none;
     }
   
     .submit2{
    
      display: block;
      }
}
